<template>
  <div class="v-application">
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" top text>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'SnackBar',
  data () {
    return {
      snackbar: true,
      timeout: 2500,
      message: '',
      color: 'success'
    }
  }
}
</script>
<style>
  /* .v-snack--multi-line .v-snack__content {
    text-align: center;
  }
  .v-snack__wrapper.theme--dark {
    background-color: gray;
    color: white;
  } */
</style>
