const constantList = {
  language: [
    'All',
    'English',
    'Hindi',
    'Tamil',
    'Bengali',
    'Telugu',
    'Kannada',
    'Gujarati',
    'Punjabi',
    'Assamese',
    'Malayalam',
    'Marathi',
    'Bhojpuri',
    'Rajasthani',
    'Odia',
    'Tripuri',
    'Kashmiri',
    'Marwadi',
    'Haryanvi',
    'Himachali'
  ],
  category: [
    'All',
    'Vlogs',
    'Money Earning',
    'Music',
    'News',
    'Knowledge',
    'Gaming',
    'Finance',
    'Animation',
    'Prank and Reaction',
    'Entertainment',
    'Beauty & Fashion',
    'Technology',
    'Food',
    'Fantasy',
    'Education',
    'Motivational',
    'Sports',
    'Health & Fitness',
    'Adult Videos',
    'Devotional',
    'Automobile',
    'Movie review'
  ],
  instaCategoryList: [
    'All',
    'Travel',
    'Technology',
    'Animation',
    'Finance',
    'Business and Marketing',
    'Celebrity',
    'Entertainment',
    'Lifestyle',
    'Pages',
    'Beauty & Fashion',
    'Food'
  ],
  country: [
    'India',
    'USA',
    'Brazil',
    'Australia',
    'Russia',
    'Canada',
    'Singapore',
    'Japan',
    'France',
    'Egypt',
    'Thailand',
    'Indonesia',
    'Qatar',
    'Taiwan',
    'Mexico',
    'Korea',
    'Argentina'
  ],
  ytCategory: [{
    value: '1',
    label: 'Film & Animation'
  }, {
    value: '2',
    label: 'Autos & Vehicles'
  }, {
    value: '10',
    label: 'Music'
  }, {
    value: '15',
    label: 'Pets & Animals'
  }, {
    value: '17',
    label: 'Sports'
  }, {
    value: '19',
    label: 'Travel & Events'
  }, {
    value: '20',
    label: 'Gaming'
  }, {
    value: '22',
    label: 'People & Blogs'
  }, {
    value: '23',
    label: 'Comedy'
  }, {
    value: '24',
    label: 'Entertainment'
  }, {
    value: '25',
    label: 'News & Politics'
  }, {
    value: '26',
    label: 'Howto & Style'
  }, {
    value: '27',
    label: 'Education'
  }, {
    value: '28',
    label: 'Science & Technology'
  }, {
    value: '30',
    label: 'Movies'
  }],
  campaignStatus: [{
    value: 0,
    label: 'Draft'
  }, {
    value: 1,
    label: 'Progress'
  }, {
    value: 2,
    label: 'Cancelled'
  }, {
    value: 4,
    label: 'Completed'
  }],
  contentYoutube: [{
    value: 1,
    label: 'Integrated Video'
  }, {
    value: 2,
    label: 'Dedicated Video'
  }, {
    value: 3,
    label: 'Shorts'
  }],
  contentInstagram: [{
    value: 4,
    label: 'Video Story'
  }, {
    value: 5,
    label: 'Static Story'
  }, {
    value: 6,
    label: 'Video Post'
  }, {
    value: 7,
    label: 'Static Post'
  }, {
    value: 8,
    label: 'Reels'
  }],
  campaignTypeList: [{
    value: 1,
    label: 'Barter'
  }, {
    value: 2,
    label: 'Cost Per View'
  }, {
    value: 3,
    label: 'Cost Per Video'
  }],
  platformList: [{
    value: 1,
    label: 'YouTube'
  }, {
    value: 2,
    label: 'Instagram'
  }],
  genderList: ['Male', 'Female', 'Animation', 'Group'],
  instaAccountTypeList: [{
    value: 0,
    label: 'Personal'
  }, {
    value: 1,
    label: 'Business'
  }],
  instaVerifyList: [{
    value: 0,
    label: 'Not Verified'
  }, {
    value: 1,
    label: 'Verified'
  }]
}
export default constantList
