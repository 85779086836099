import Vue from 'vue'
import constantData from '@/utils/constant'

Vue.filter('formatNumber', function (value) {
  if (!value) return ''

  const thousand = 1000
  const million = 1000000
  const billion = 1000000000
  const trillion = 1000000000000

  var output
  if (value < thousand) {
    return String(value)
  }
  if (value === 1000000) {
    output = value / million
    return `${output.toFixed(1)}M`
  }
  if (value >= thousand && value <= million) {
    output = value / thousand
    return `${output.toFixed(1)}K`
  }

  if (value >= million && value <= billion) {
    output = value / million
    return `${output.toFixed(1)}M`
  }

  if (value >= billion && value <= trillion) {
    output = value / billion
    return `${output.toFixed(1)}B`
  } else {
    return `${output.toFixed(1)}T`
  }
})

Vue.filter('dateFormat', function (date) {
  if (!date) return ''

  var d = new Date(date)
  var formattedDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
  return formattedDate
})

Vue.filter('platform', function (value) {
  if (value === null) return '-'
  const filtered = constantData.platformList.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  } else {
    return '-'
  }
})

Vue.filter('campaignType', function (value) {
  if (!value) return '-'

  if (value) {
    const filtered = constantData.campaignTypeList.filter(row => row.value === value)
    if (filtered.length) {
      return filtered[0].label
    } else {
      return '-'
    }
  }
})

Vue.filter('youtube', function (value) {
  if (!value) return '-'

  const filtered = constantData.contentYoutube.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  } else {
    return '-'
  }
})

Vue.filter('campaignStatus', function (value) {
  if (value === null) return ''

  const filtered = constantData.campaignStatus.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  } else {
    return '-'
  }
})

Vue.filter('instagram', function (value) {
  if (!value) return '-'

  const filtered = constantData.contentInstagram.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  } else {
    return '-'
  }
})
Vue.filter('accountType', function (value) {
  if (value === null) return '-'
  const filtered = constantData.instaAccountTypeList.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  } else {
    return '-'
  }
})
Vue.filter('verify', function (value) {
  if (value === null) return '-'
  const filtered = constantData.instaVerifyList.filter(row => row.value === value)
  if (filtered.length) {
    return filtered[0].label
  } else {
    return '-'
  }
})
